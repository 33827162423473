@import "./styles/globals.scss";

.main {
    position: fixed;
    touch-action: none;
    overflow: hidden;
    top: 0;
    left: 0;
}

.images {
    position: absolute;
    top: 0;
    left:0;
    overflow: hidden;
}

.image {
    position: absolute;
    top: 0px;
    left:0px;
    opacity: 0;

    &.visible {
        opacity: 1;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left:0;
    overflow: hidden;

    mix-blend-mode: difference;
}

.mobileAbout {
    position: absolute;
    top: 0;
    left:0;
    padding: 15px;
    opacity: 0;

    &.visible {
        opacity: 1;
    }
}

.filter {
    width: 100%;
    height: 100%;
    background-color: rgba(10, 7, 14, 0.8);
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;

    pre {
        opacity: 1;
    }
}

.heading {
    position: absolute;
    width: 35%;
    left: 32.5%;
    text-align: center;
    height: auto;
    padding-top: 20px;
    
    @include sm {
        padding-top: 28px;
        width: 100%;
        left: 0%;
    }
}

.title {
    position: absolute;
    bottom: 0;
    width: 35%;
    left: 32.5%;
    text-align: center;
    height: auto;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    
    @include sm {
        padding-bottom: 28px;
        width: calc(100% - 60vh - 60px);
        left: calc(30vh + 30px);
        padding-left: 5px;
        padding-right: 5px;
    }
}

.corner {
    position: absolute;
    width: 28vw;
    height: 28vw;

    display: flex;
    flex-direction: column;
    

    @include sm {
        width: 30vh;
        height: 30vh;
    }


    &.topleft {
        top: 15px;
        left: 15px;
        padding-top: 10px;
        padding-left: 10px;
        border-top: 1px solid $grey;
        border-left: 1px solid $grey;
        align-items: flex-start;
        justify-content: flex-start;

        @include sm {
            top: 30px;
            left: 30px;
        }

        p:hover {
            cursor: pointer;
        }
    }

    &.topright {
        top: 15px;
        right: 15px;
        padding-top: 10px;
        padding-right: 10px;
        border-top: 1px solid $grey;
        border-right: 1px solid $grey;
        align-items: flex-end;
        justify-content: flex-start;

        @include sm {
            top: 30px;
            right: 30px;
        }
    }

    &.botleft {
        bottom: 15px;
        left: 15px;
        padding-bottom: 10px;
        padding-left: 10px;
        border-bottom: 1px solid $grey;
        border-left: 1px solid $grey;
        align-items: flex-start;
        justify-content: flex-end;

        @include sm {
            bottom: 30px;
            left: 30px;
        }
    }

    &.botright {
        bottom: 15px;
        right: 15px;
        padding-bottom: 10px;
        padding-right: 10px;
        border-bottom: 1px solid $grey;
        border-right: 1px solid $grey;
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;

        @include sm {
            bottom: 30px;
            right: 30px;
        }
    }
}

.focus {
    position: absolute;
    width: 85%;
    left: 7.5%;
    height: 55%;
    top: 22.5%;
    z-index: 11;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include sm {
        width: 55%;
        left: 22.5%;
        height: 60%;
        top: 20%;
    }

    &.hidden {
        opacity: 0;
    }
}

.crossBox {
    width: 14.2%;
    height: 14.2%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}