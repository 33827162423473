@import './colors.scss';
@import './breakpoints.scss';
@import './type.scss';
@import './reset.scss';


body {
    max-width: 100vw;
    
}

html, body, div, a, p, h1, h2, pre {
    font-family: 'Simplon Mono';
    font-weight: 300;
    font-style: normal;
    color: $grey;
    font-size: 17px;
    text-decoration: none;

    font-feature-settings:'kern';        /* turns on kerning */ 
    text-rendering: optimizeLegibility;  /* emphasise on legibility when rendering, turns on ligatures and kerning */    
    -webkit-font-smoothing: antialiased; /* apply font anti-aliasing for Webkit on OSX */    
    -moz-osx-font-smoothing: grayscale;  /* apply font anti-aliasing for Firefox on OSX */

    @include sm {
        font-size: 20px;
    }

    &::selection {
        color: black; 
        background: $grey;
    }
}

pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */    
    z-index: 99;
    

    

    @include sm {
        width: calc(100vw - 60px);
        padding-top: 10px;
        background: $grey;
        color: black;
        mix-blend-mode: none;
        isolation: isolate;
        background: none;
        color: $grey;
        width: 130%;
    }
}

a {

    &:hover {
        color: black; 
        background: $grey;
    }
}